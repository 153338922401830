@layer base {
  [type='text'],
  [type='email'],
  [type='url'],
  [type='password'],
  [type='number'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='week'],
  [multiple],
  textarea,
  select {
    @apply focus:ring-2 focus:ring-brabners-copper-300;
  }

  [type='checkbox'],
  [type='radio'] {
    @apply text-brabners-copper-300 focus:ring-brabners-copper-300 focus:ring-offset-brabners-navy-400;
  }

  /* Dark checkmark - https://github.com/tailwindlabs/tailwindcss-forms/issues/17#issuecomment-765733466 */
  [type='checkbox']:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23222732' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  }
  [type='radio']:checked {
    /* https://github.com/tailwindlabs/tailwindcss-forms/issues/17#issuecomment-765733466 */
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23222732' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
  }

  /* [type='checkbox'] {
    @apply focus:ring-2 focus:ring-brabners-copper-300;
  } */

  [type='text'],
  [type='email'],
  [type='url'],
  [type='password'],
  [type='number'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='week'],
  [multiple],
  textarea,
  select {
    @apply rounded-full border-0 px-5 py-2;
  }
}

@layer components {
  .form-error-field {
    @apply border-red-300 focus:ring-red-400;
  }

  .form-error-message {
    @apply mt-2 text-sm font-semibold text-red-400;
  }
}
