@layer components {
  .rich-text {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @apply mb-5 font-serif font-black text-brabners-heading;
    }

    h1 {
      @apply text-4.5xl lg:text-6xl;
    }

    h2 {
      @apply text-3xl lg:text-3xl;
    }

    h3,
    h4 {
      @apply text-2xl lg:text-2xl;
    }

    h5,
    h6 {
      @apply text-2xl lg:text-xl;
    }

    image {
      @apply h-auto w-full;
    }

    p:not(:last-child),
    ul:not(:last-child) {
      @apply mb-5;
    }

    ol,
    ul {
      @apply pl-5;

      li {
        @apply pl-2;
      }
    }

    ul {
      @apply list-disc;
    }

    ol {
      @apply list-decimal;
    }

    table {
      @apply w-full;
    }

    th,
    td {
      @apply border border-current p-3;
    }

    th {
      @apply text-left font-bold;
    }

    a {
      /* Same colour as headings */
      @apply text-brabners-heading;
    }

    a[href] {
      /* Don't underline empty anchor added by ckeditor anchor option */
      @apply hover:underline;
    }
  }
}
