@layer components {
  .btn {
    @apply inline-block rounded-full bg-brabners-copper-300 px-8 pb-3.5 pt-3 text-base leading-none text-brabners-black-500 transition-colors duration-500 hover:bg-brabners-beige focus:outline-none focus:ring-2 focus:ring-brabners-copper focus:ring-offset-4;

    &.secondary {
      @apply bg-brabners-navy-200 text-white hover:bg-brabners-navy-100;
    }

    &.on-dark {
      @apply hover:bg-brabners-mushroom focus:ring-offset-brabners-navy-300;
    }
  }
}
