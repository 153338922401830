/*
  Not importing this in Carousel.tsx because of the same specifitity between:

  - Swiper core styles (included in Insights.tsx)
  - These overrides
  - Utility classes eg. [--swiper-navigation-sides-offset:1rem]
*/
swiper-container,
/* Normally swiper elements are inside .swiper-container but there are cases where navigation
elements are outside it and need to inherit the same styles */
.brabners-swiper-wrapper {
  --swiper-theme-color: theme(colors.brabners.copper.600);

  --swiper-navigation-size: 26px;
  --swiper-navigation-sides-offset: 6%;

  /* --swiper-pagination-color: theme(colors.brabners.copper.600); */
  --swiper-pagination-bullet-inactive-color: theme(colors.brabners.storm);
  --swiper-pagination-bullet-size: 0.875rem; /* 14px */
  --swiper-pagination-bullet-horizontal-gap: 0.625rem; /* 10px */
  --swiper-pagination-bullet-inactive-opacity: 1;
}
