/* Using @import instead of @tailwind for compatility between @layer and @import. postcss-import
requires that import comes first, so we can't use @tailwind -
https://github.com/tailwindlabs/tailwindcss.com/issues/991#issuecomment-981743229 */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Base */
@import './typography.css';

/* Components */
@import './components/btn.css';
@import './components/container.css';

/* XXX Maybe move these to the ts components? */
@import './components/form.css';
@import './components/rich-text.css';

/* Overrides */
@import './overrides/swiper.css';
