@layer components {
  .container {
    /* Ignore TW's responsive max-width and add px and mx */
    @apply mx-auto w-full max-w-container px-10 xl:px-0;
  }

  /*
    These utilities act like .container < md, and half of container >= md. Essentially they have margin
    auto on one side, and the same padding as .container on the other. This allows them to be used in
    2-col layouts where the other col is half-width (eg. an image), and the content will still line up
    with full-width .container componponents. See c-text-media-split as an example.
  */
  .container-half-left {
    @apply ml-auto mr-auto w-full pl-10 pr-32 md:mr-0 md:max-w-container-half xl:pl-0;
  }

  .container-half-right {
    @apply ml-auto mr-auto w-full pl-32 pr-10 md:ml-0 md:max-w-container-half xl:pr-0;
  }

  /* ========================= */

  .container-1-2-left,
  .container-1-2-right,
  .container-2-1-left,
  .container-2-1-right {
    /* @apply ml-auto mr-auto w-full px-0 pl-10 pr-10; */
    @apply mx-auto w-full px-10;
  }

  .container-1-2-left {
    @apply lg:mr-0 lg:max-w-container-one-third xl:pl-0;
  }
  .container-1-2-right {
    @apply lg:ml-0 lg:max-w-container-two-third xl:pr-0;
  }

  /* ========================= */

  .container-2-1-left {
    @apply lg:mr-0 lg:max-w-container-two-third xl:pl-0;
  }
  .container-2-1-right {
    @apply ml-0 lg:max-w-container-one-third xl:pr-0;
  }
}
